<template>
  <div>
    <SmallNav />
    <div class="safe_holder">
      <div class="container">
        <div class="safe_text_holder">
          <h4 class="your_project">
            <img src="../assets/images/safe.svg" alt="safe" />
            <span @click="back"> Back</span>
          </h4>
          <div class="projects_link">
            <router-link to="/dashboard/newproject">
              <h6 class="btn">
                <img src="../assets/images/addplus.svg" alt="projects" />
                New Project
              </h6>
            </router-link>
          </div>
        </div>
        <div v-if="pageLoader">
          <PageLoader></PageLoader>
        </div>
        <div v-else>
          <!-- Project list begins -->
          <div v-if="projects.length == 0" class="empty__state">
            <div class="empty__div">
              <img src="../assets/images/empty-state.svg" alt="" />
              <p>You don't have a running project at the moment.</p>
            </div>
          </div>
          <!-- <div class="project-text-holder" v-if="projects.length > 0">
            <h3 class="project-list">Projects</h3>
          </div> -->
          <div class="table-wrap clearfix" v-if="projects.length > 0">
            <div class="table-responsive">
              <table class="table seperate_border">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Project Title</th>
                    <th scope="col">Project Amount</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Amount Left</th>
                    <th scope="col">Days left</th>
                    <th scope="col">PI</th>
                    <th scope="col">More</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in projects" :key="index">
                    <th scope="row">
                      <div v-if="(item.status === 'active') && (item.ended_and_collected_fund == 'NO') && (item.ended_and_transfered_to_project_id == 'NO') && (item.ended_and_transfered_to_safe == 'NO') && (item.ended_payment_made == 'NO')" class="green-dot"></div>
                      <div v-if="(item.status === 'inactive') && (item.ended_and_collected_fund == 'NO') && (item.ended_and_transfered_to_project_id == 'NO') && (item.ended_and_transfered_to_safe == 'NO') && (item.ended_payment_made == 'NO')" class="yellow-dot"></div>
                      <div v-if="(item.status === 'closed') && (item.ended_and_collected_fund == 'NO') && (item.ended_and_transfered_to_project_id == 'NO') && (item.ended_and_transfered_to_safe == 'NO') && (item.ended_payment_made == 'NO')" class="red-dot"></div>
                      <div style="margin: auto; width: 11px;">
                        <img style="width: 15px;" v-if="item.ended_and_collected_fund == 'YES' || item.ended_and_transfered_to_project_id == 'YES' || item.ended_and_transfered_to_safe == 'YES' || item.ended_payment_made == 'YES'" src="../assets/images/blackluck.svg" alt="luck">
                      </div>
                    </th>
                    <td style="font-weight: bold; color: black;">
                      {{ item.title }}
                    </td>
                    <td v-if="item.amounttype === 'fixed'">
                      &#8358;{{ Number(item.amountforfixed).toLocaleString() }}
                    </td>
                    <td v-if="item.amounttype === 'range'">
                      &#8358;{{ Number(item.maxamountforrange).toLocaleString() }}
                    </td>
                    <td>{{ item.enddate }}</td>
                    <td v-if="item.amountleft == '' || item.amountleft == null">
                      -
                    </td>
                    <td v-else>
                      &#8358;{{ Number(item.amountleft).toLocaleString() }}
                    </td>
                    <td>
                      {{ item.daysleft }}
                    </td>
                    <th v-if="item.PI == '' || item.PI == null">-</th>
                    <th v-else>{{ item.PI }}%</th>
                    <!-- <th><span class="details_btn" @click="moveTo(item.id)">Details</span></th> -->
                    <th class="menu_holder">
                      <div class="menu_dot dropdown">
                        <div data-toggle="dropdown" aria-expanded="false">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </div>
                        <div class="dropdown-menu">
                          <button
                            class="dropdown-item"
                            type="button"
                            @click="moveTo(item.id)"
                          >
                            Details
                          </button>
                          <hr class="menu_hr" />

                          <button
                            :disabled="(item.fundtype === 'fromsafe' || item.daysleft == '0' || item.ended_and_collected_fund == 'YES' || item.ended_and_transfered_to_project_id == 'YES' || item.ended_and_transfered_to_safe == 'YES' || item.ended_payment_made == 'YES')"
                            class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#autodebitproject"
                            @click="
                              singleProjectDetails = item;
                              enddate = item.enddate;
                              getCurrentDay();
                            "
                          >
                            Automatic Debit
                          </button>
                          <button
                            :disabled="item.APF > 0 || item.ended_and_collected_fund == 'YES' || item.ended_and_transfered_to_project_id == 'YES' || item.ended_and_transfered_to_safe == 'YES' || item.ended_payment_made == 'YES'"
                            class="dropdown-item"
                            type="button"
                            @click="
                              singleProjectDetails = item;
                              enddate = item.enddate;
                              triggerAction('edit', item);
                            "
                          >
                            Edit
                          </button>
                          <button
                            :disabled="item.APF > 0 || item.ended_and_collected_fund == 'YES' || item.ended_and_transfered_to_project_id == 'YES' || item.ended_and_transfered_to_safe == 'YES' || item.ended_payment_made == 'YES'"
                            class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#deleteProjectConfirm"
                            @click="
                              singleProjectDetails = item;
                              enddate = item.enddate;
                              triggerAction('delete', item);
                            "
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </th>
                    <!-- <th><button class="btn payout">Payout</button></th> -->
                    <!-- <th>
                    <div class="red-dot"></div>
                  </th> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-5"></div>
          <!-- Projects list ends -->

          <!-- Modal for auto debit project begins -->
          <div
            class="modal fade"
            id="autodebitproject"
            tabindex="-1"
            role="dialog"
            aria-labelledby="projectautodebit"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content fundmodalContent">
                <div
                  class="closeFundProject"
                  style="border-radius: 20px 20px 0 0;"
                >
                  <h6>Automatic Debit</h6>
                  <small data-dismiss="modal" aria-label="Close">x</small>
                </div>
                <div class="modal-body">
                  <p class="transferSafeBalance">
                    Amount Left:
                    <span>
                      &#8358;{{
                        Number(singleProjectDetails.amountleft).toLocaleString()
                      }}
                    </span>
                  </p>
                  <form ref="form">
                    <div class="form-group">
                      <label for="frequency">Select Frequency</label>
                      <select
                        class="form-control"
                        id="frequency"
                        v-model="modeofsafing"
                      >
                        <option selected disabled value="">--Select--</option>
                        <option
                          v-for="(item, index) in modeOfSafeProject"
                          :key="index"
                          :value="item.id"
                          >{{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="startdate">Start Date</label>
                      <input
                        @change="calculateDate"
                        type="date"
                        class="form-control"
                        id="startdate"
                        v-model="startdate"
                      />
                    </div>

                    <div class="form-group">
                      <label for="enddate">End Date</label>
                      <input
                        disabled
                        type="date"
                        class="form-control"
                        id="enddate"
                        v-model="singleProjectDetails.enddate"
                      />
                      <!-- calculateDate -->
                    </div>

                    <div v-show="modeofsafing != '' && startdate != ''">
                      <p class="break_down_text">Breakdown</p>
                      <!-- <hr /> -->
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <tbody class="summary_table">
                            <tr>
                              <td>Daily</td>
                              <td class="bold-text" style="text-align: right">
                                &#8358;{{
                                  Number(debitAmoutOptionDaily).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Weekly</td>
                              <td class="bold-text" style="text-align: right">
                                &#8358;{{
                                  Number(
                                    debitAmoutOptionWeekly
                                  ).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Monthly</td>
                              <td class="bold-text" style="text-align: right">
                                &#8358;{{
                                  Number(
                                    debitAmoutOptionMonthly
                                  ).toLocaleString()
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="form-group transfer-btn">
                      <button
                        class="btn btn-block"
                        @click.prevent="autoDebit"
                        :disabled="autoDebitLoader"
                      >
                        Activate Auto Debit
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="autoDebitLoader"
                        ></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal for auto debit project ends -->

          <!-- Delete project modal starts -->
          <div class="modal" tabindex="-1" id="deleteProjectConfirm">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header title-modal2">
                  <h5 class="modal-title">Delete Project</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p class="confrim-delete-text">
                    Are you sure you want to delete this project?
                  </p>
                </div>
                <div class="confirm-delete">
                  <button
                    class="btn cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button class="btn delete" @click="deleteProject">
                    Delete
                    <i
                      class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                      v-if="deleteLoader"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Delete project modal ends -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";

export default {
  name: "SafeProjects",
  components: {
    SmallNav,
    PageLoader
    // Footer,
  },
  data() {
    return {
      pageLoader: false,
      autoDebitLoader: false,
      deleteLoader: false,
      user: "",
      projects: [],
      startdate: "",
      enddate: "",
      modeofsafing: "",
      modeOfSafeProject: [],
      singleProjectDetails: "",
      debitAmoutOptionDaily: "",
      debitAmoutOptionWeekly: "",
      debitAmoutOptionMonthly: "",
      dataDetails: "",
      projectAmount: ""
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.getProjects();
    this.getCurrentDay();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    triggerAction(action, data) {
      this.getCurrentDay();
      if (action == "edit") {
        Api.pushProjectDetails(data);
        this.$router
          .push({
            path: `/dashboard/editproject`
          })
          .catch(() => {
            // console.log("none")
          });
        // console.log("project details", data);
      }
      this.getCurrentDay();
      if (action == "delete") {
        this.dataDetails = data;
      }
    },
    moveTo: function(id) {
      this.$router
        .push({
          path: `/dashboard/safeprojectdetails/${id}`
        })
        .catch(() => {
          // console.log("none")
        });
      // this.$router.push("/blog/${id}");
    },
    getCurrentDay() {
      // console.log("single project", this.singleProjectDetails);
      const today = new Date().toISOString().split("T")[0];
      document.getElementById("startdate").setAttribute("min", today);
      document.getElementById("enddate").setAttribute("min", today);
    },
    fetchModeOfProjectForDebit() {
      Api.getRequest(`fetchmodeofsafingforprojects`).then(res => {
        // console.log("Mode of project debit", res);
        if (res.data.success) {
          this.modeOfSafeProject = res.data.modes;
        } else {
          this.$store.commit("setApiFailed", res.data.error);
        }
      });
    },
    autoDebit() {
      this.autoDebitLoader = true;
      // this.enddate = this.singleProjectDetails.enddate;
      // this.$refs.form.reset();
      if (
        this.startdate === "" ||
        this.enddate === "" ||
        this.modeofsafing === ""
      ) {
        this.autoDebitLoader = false;
        return this.$store.commit("setApiFailed", "All fields are required!");
      }
      if (this.singleProjectDetails.amountleft == 0) {
        this.autoDebitLoader = false;
        return this.$store.commit("setApiFailed", "Insuficient safe balance!");
      }
      this.checkMonthWithDate();
      if (this.autoDebitLoader == false) {
        return this.checkMonthWithDate();
      }
      Api.postRequest(`switchprojectfundtypetoscheduled`, {
        startdate: this.startdate,
        modeofsafing: this.modeofsafing,
        projectid: this.singleProjectDetails.id
      })
        .then(res => {
          // console.log("Auto debit done", res);
          // this.$refs.autoDebitForm.reset();
          this.autoDebitLoader = false;
          if (res.data.success) {
            $("#autodebitproject")
              .removeClass("fade")
              .modal("hide");
              this.getProjects();
            return this.$store.commit("setApiSuccess", res.data.success);
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log("err", err);
          this.autoDebitLoader = false;
        });
    },
    getProjects() {
      this.pageLoader = true;
      Api.getRequest(`getusersafeprojectsbyuserid/${this.user.id}`)
        .then(res => {
          // console.log("Projects", res);
          if (res.data.success) {
            this.pageLoader = false;
            this.projects = res.data.projects;
            if (res.data.incomplete_project != null) {
              this.projects.push(res.data.incomplete_project);
            }
            this.fetchModeOfProjectForDebit();
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log("err", err);
          this.pageLoader = false;
        });
    },
    openPanel: function(id) {
      // console.log("Index", id);
      this.panel = id;
      const coll = document.getElementsByClassName("collapsible");
      let i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
          // console.log("Index", coll);
          this.classList.toggle("active");
          const content = this.nextElementSibling;
          if (content.style.maxHeight) {
            // console.log("Next sibline", content);
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
            // console.log("Next sibline2", content);
          }
        });
      }
    },
    calculateDate() {
      // Daily pick
      if (this.singleProjectDetails.amounttype == "fixed") {
        this.projectAmount = this.singleProjectDetails.amountforfixed;
      }
      else {
        this.projectAmount = this.singleProjectDetails.maxamountforrange;
      }
      const day = 1000 * 60 * 60 * 24;
      const date1 = new Date(this.startdate).getTime();
      const date2 = new Date(this.enddate).getTime();
      const difference2 = Math.abs(date2 - date1);
      this.daily = Math.floor(difference2 / day + 1);
      this.debitAmoutOptionDaily = (Number(this.projectAmount) / this.daily).toFixed(2);

      // Weekly pick
      const oneweek = 1000 * 60 * 60 * 24 * 7;
      const d1 = new Date(this.startdate).getTime();
      const d2 = new Date(this.enddate).getTime();
      const difference = Math.abs(d2 - d1);
      this.weekly = Math.floor(difference / oneweek + 1);
      this.debitAmoutOptionWeekly = (Number(this.projectAmount) / this.weekly).toFixed(2);

      // Monthly pick
      let months;
      months =
        (new Date(this.enddate).getYear() -
          new Date(this.startdate).getYear()) *
        12;
      months -= new Date(this.startdate).getMonth();
      months += new Date(this.enddate).getMonth();
      this.monthly = months <= 0 ? 0 : months + 1;
      this.debitAmoutOptionMonthly = (Number(this.projectAmount) / this.monthly).toFixed(2);

      this.checkMonthWithDate();
    },
    checkMonthWithDate() {
      const startdate = Date.parse(this.enddate);
      const enddate = Date.parse(this.startdate);
      if (startdate <= enddate) {
        this.autoDebitLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "Start date should be less than End date"
        );
      }
      if (this.modeofsafing == 3 && this.monthly < 1) {
        this.autoDebitLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one month from today"
        );
      }
      if (this.modeofsafing == 3 && this.monthly < 1) {
        this.autoDebitLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one month from today"
        );
      }
      if (this.modeofsafing == 2 && this.weekly === 0) {
        this.autoDebitLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one week from today"
        );
      }
      if (this.modeofsafing == 1 && this.daily < 3) {
        this.autoDebitLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than three days from today"
        );
      }
    },
    deleteProject() {
      this.deleteLoader = true;
      Api.postRequest(`deletesafeproject`, {
        projectid: this.dataDetails.id
      })
        .then(res => {
          this.deleteLoader = false;
          if (res.data.success) {
            this.$store.commit("setApiSuccess", res.data.success);
            this.getProjects();
            $("#deleteProjectConfirm")
              .removeClass("fade")
              .modal("hide");
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
          this.deleteLoader = false;
        });
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$limeGreen: #3ec250;

// Lato fonts

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}

.empty__div {
  padding: 1rem;

  img {
    width: 100%;
    max-width: 470.58px;
  }

  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  // p:nth-child(3) {
  //   font-family: Gotham;
  //   font-style: normal;
  //   font-weight: bold;
  //   font-size: 16px;
  //   line-height: 30px;
  //   align-items: center;
  //   color: #000000;
  // }
}

.safe_holder {
  margin-top: 20px;
  height: 100%;
  padding-bottom: 1px;
  // height: 100vh;
}

.safe_text_holder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
}

.your_project {
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }

  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;

  span {
    color: #1765ca;
    cursor: pointer;
  }
}

.projects_link {
  h6 {
    background: #3059eb;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    border-radius: 4px;
    cursor: pointer;

    img {
      padding-right: 5px;
    }
  }
}

.project-text-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.color-dots-holder {
  display: flex;

  span {
    padding: 0px 10px 0 3px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $black;
  }
}

.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
  margin-top: 3px;
}

.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffb100;
  margin: auto;
  margin-top: 3px;
}

.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
  margin-top: 3px;
}

.menu_holder {
  display: flex;
}

.menu_dot {
  display: flex;
  justify-content: center;
  cursor: pointer;

  span {
    padding: 2px;
    font-weight: bold;
  }

  div:first-child {
    transform: rotate(270deg);
  }
}

.menu_hr {
  // margin-top: 0;
  // margin-bottom: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dropdown-item {
  font-family: Lato;
  font-style: italic;
  font-size: 15px;
}

.project-list {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  text-align: left;
}

.table-wrap {
  background: $secondary;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.seperate_border {
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.seperate_border {
  thead {
    th,
    td {
      border: none !important;
      font-family: Lato;
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      color: $black;
      padding-bottom: 0;
    }
  }
}

.seperate_border {
  th,
  td {
    border-spacing: 0 15px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 30px;
    color: #6c6c6c;
    white-space: nowrap;
  }

  th:first-child {
    border-left: 1px solid #e5e5e5;
    border-radius: 5px 0 0 5px;
  }

  th:last-child {
    border-right: 1px solid #e5e5e5;
    border-radius: 0px 5px 5px 0px;
    font-weight: bold !important;
    color: $black !important;
  }
}

.details_btn {
  border: 1px solid $primary;
  color: $primary;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  padding: 5px;
  cursor: pointer;
}

.payout {
  background: #ffb100;
  font-size: 10px;
  font-weight: bold;
  font-family: Lato;
  box-shadow: none;
  outline: 0;
}

// Modal for project Auto debit
.closeFundProject {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;

  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: $black;
  }

  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.fundmodalContent {
  border-radius: 20px;
  margin-bottom: 50px;

  input,
  select {
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    height: 45px;
    box-shadow: none;
    outline: 0;
    // color: #b1b2b2;
    color: $black;
    font-size: 15px;
    margin-bottom: 25px;
    font-family: Gotham;
  }
}

.transferSafeBalance {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #262626;
  text-align: left;
  padding-top: 10px;

  span {
    color: $primary;
  }
}

.transfer-btn {
  button {
    box-shadow: none;
    outline: 0;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 45px;
  }
}

.summary_table {
  text-align: left;

  td {
    border-top: none;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 40px;
    color: $black;
  }

  .bold-text {
    font-weight: bold;
  }
}

.break_down_text {
  padding: 10px 10px;
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $primary;
  margin-bottom: 0;
}

// Modal for auto debit project

// Modal to confirm delete starts
.confrim-delete-text {
  font-family: Gotham;
  font-size: 20px;
  font-weight: normal;
  color: $black2;
}

.title-modal2 {
  background: $primary;
  padding: 1rem 2rem;
  h5 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
}

.confirm-delete {
  display: flex;
  justify-content: center;
  button {
    font-family: Gotham;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 20px;
    outline: 0;
    box-shadow: none;
  }
  .cancel {
    color: $primary;
    border: 1px solid $primary;
    margin-right: 10px;
  }
  .delete {
    color: $secondary;
    background: #ea0000;
  }
}
// Modal to confirm delete ends

.form-group {
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    color: #000000;
  }

  select,
  input {
    height: 39.68px;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $black;
    text-transform: capitalize;
  }

  textarea {
    // text-transform: lowercase;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $black;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 599px) {
  .empty__div {
    p:nth-child(3) {
      font-size: 13px;
    }
  }
}
</style>
